import { Grid, Header } from 'semantic-ui-react'
import { ErrorBanner } from '@/layouts/Navbar/components'
import './HelpPage.css'

export default function HelpPage() {
  return (
    <div className='main'>
      <div className='App-header'>
        <Grid className='message-helppage' container stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' content='Help' textAlign='left' style={{ color: 'rgb(0, 94, 170)' }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h4' content='Support Mailbox' textAlign='left' />
              <p>
                For technical assistance, please contact{' '}
                <a href='https://nationaldppcsc.cdc.gov/s/' target='_blank' rel='noopener noreferrer'>
                  CDC National DPP Customer Service Center (CSC)
                </a>{' '}
                who will direct your inquiry.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h4' content='User Guides' textAlign='left' />
              {/* <a href='\documents\Community Asset Map User Guide v1.0.pdf' target='_blank'>
                <p>Community Asset Map troy</p>
              </a> */}
              <a href='\documents\Enrollment Tracker User Guide v3.0.2.pdf' target='_blank'>
                <p>Enrollment Tracker</p>
              </a>
              <a href='\documents\Recognized Organization Site Explorer User Guide v3.0.pdf' target='_blank'>
                <p>Recognized Organization Site Explorer</p>
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h4' content='Quick Reference Guides' textAlign='left' />
              {/* <a href='\documents\QuickReference-CAM.pdf' target='_blank'>
                <p>Community Asset Map</p>
              </a> */}
              <a href='\documents\QuickReference-ET_3.0.1.pdf' target='_blank'>
                <p>Enrollment Tracker</p>
              </a>
              <a href='\documents\QuickReference-ROSE_3.0.pdf' target='_blank'>
                <p>Recognized Organization Site Explorer</p>
              </a>
              <a href='\documents\QuickReference-RAM_3.0.pdf' target='_blank'>
                <p>Risk Assessment Module</p>
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h4' content='SAMS Help' textAlign='left' />
              <Header as='h5' content='SAMS Account Registration & Authorization' textAlign='left' id='registration' />
              <p>
                If you do not have a SAMS user name and password, please reach out to the{' '}
                <a href='https://nationaldppcsc.cdc.gov/s/' target='_blank' rel='noopener noreferrer'>
                  CDC National DPP Customer Service Center (CSC)
                </a>
                . Once you are verified as a contact for your organization, you will receive an &quot;Invitation to
                Register&quot; email to register with the SAMS Partner Portal from Sams-No-Reply (CDC)
                &lt;sams-no-reply@cdc.gov&gt;. Refer to the{' '}
                <a href='https://auth.cdc.gov/sams/SAMSUserGuide.pdf' target='_blank' rel='noopener noreferrer'>
                  SAMS Registration User Guide
                </a>{' '}
                for help with registration. You have 30 days to submit your registration.
              </p>
              <br />
              <p>
                Once you submit your registration online, the CDC SAMS Administrator will approve & you will receive two
                emails, 1) CDC SAMS Account Registration &quot;SAMS Account Activation&quot; and 2) &quot;SAMS Activity
                Authorization&quot; granting access to the DPRP Data Submission Portal with links. The approval should
                take no longer than 3 business days.
              </p>
              <Header as='h5' content='SAMS Password Changes' textAlign='left' id='pwdchange' />
              <p>
                Your password will expire every 60 days. Once it expires, you will automatically be prompted to change
                it on your next login to SAMS or the Data Submission Portal.
              </p>
              <Header as='h5' content='SAMS Forgotten Passwords' textAlign='left' id='forgotpwd' />
              <p>
                If you forget your password, you can reset it by choosing the forgotten password link on the SAMS
                Partner Portal login page. You will be prompted for your user ID and given a chance to reset your
                password using the information you supplied during registration. As a follow-up, SAMS will notify you
                that your password has been reset.
              </p>
              <Header as='h5' content='SAMS Account Lockout' textAlign='left' id='accountlockout' />
              <p>
                If you enter an incorrect user ID/password combination too many times, SAMS will lock your account for 1
                hour. This is necessary to keep your account secure. While your account is locked, you will be unable to
                login to the SAMS portal, use the DPRP Data Submission Portal, or reset your password. SAMS will
                automatically unlock your password after the lockout time has passed. If you have remembered your
                password or discovered an error, you can simply login as usual. If you can’t remember your password,
                simply follow the forgotten password link to reset it.
              </p>
              <Header
                as='h5'
                content='Quick Facts & Helpful Tips for SAMS Registration'
                textAlign='left'
                id='quickfacts'
              />
              <Header as='h6' content='What Happened?' textAlign='left' id='whathappened' />
              <p>
                If more than 3 days have gone by since you completed registration and you haven’t received an email from
                Sams-No-Reply (CDC) sams-no-reply@cdc.gov, and after confirming the message did not go to your spam or
                junk email folder, contact{' '}
                <a href='mailto:samshelp@cdc.gov' target='_blank' rel='noopener noreferrer'>
                  samshelp@cdc.gov
                </a>{' '}
                or Toll Free (877) 681-2901.
              </p>
              <Header as='h6' content='No Sharing' textAlign='left' id='nosharing' />
              <p>
                Your SAMS Partner Portal account is assigned just to you. Keep your user ID and password in a safe place
                and don’t share your account with others. Remember that you are responsible for actions performed with
                your account.
              </p>
              <Header as='h6' content='I Forgot My Password!' textAlign='left' id='iforgotmypassword' />
              <p>
                If you forget your password while waiting for your account approval to arrive (or at any time after),
                just follow the forgotten password link on the{' '}
                <a href='https://sams.cdc.gov' target='_blank' rel='noopener noreferrer'>
                  SAMS Partner Portal
                </a>{' '}
                login page to reset it.
              </p>
              <Header as='h6' content='Tech Troubles' textAlign='left' id='techtroubles' />
              <p>
                In order to access the SAMS Partner Portal, your browser must be configured to use TLS 1.0 encryption or
                newer. If your computer is not configured for TLS, or if you are unsure, please contact your local IT
                System Administrator for help.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <ErrorBanner />
    </div>
  )
}
